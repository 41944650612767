import { extractInitials } from "@/helpers/extractInitials";

import * as Presenter from "./AvatarWithSource.presenter";

interface ImageDetails {
  name: string;
  imgUrl?: string | null;
}

interface Props extends ImageDetails {
  source: ImageDetails;
}

export const ImgOrText = ({ name }: ImageDetails) => {
  // FIXME: disabled until we properly handle images (lazy loading, cache headers, etc)

  // if (imgUrl) {
  //   return <img src={imgUrl} alt={name} loading="lazy" />;
  // }

  return <div>{extractInitials(name)}</div>;
};

export const AvatarWithSource = ({ name, source, imgUrl }: Props) => {
  return (
    <Presenter.Wrapper>
      <Presenter.MainImage>
        <ImgOrText name={name} imgUrl={imgUrl} />
      </Presenter.MainImage>

      <Presenter.SourceImage>
        <ImgOrText name={source.name} imgUrl={source.imgUrl} />
      </Presenter.SourceImage>
    </Presenter.Wrapper>
  );
};
